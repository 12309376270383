// Scripts required for the website, which require the dom to be fully loaded.
import * as siteConfig from "./_variable";

ready(() => {

  let joinTheConversationComponent = document.querySelector('#join-the-conversation-news');
  fetchApiData(siteConfig.api.domain + 'news/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(news => {

    // If there is no news, then delete the join the conversation section.
    if (news.items.length === 0) {
      if (joinTheConversationComponent !== null) {
        joinTheConversationComponent.remove();
      }
      return;
    }

    // Iterate through all the found news items. Although we're only interested in the first.
    let counter = 0;
    news.items.forEach(item => {

      // After we've ran the first news item we can kill the remainder.
      if (++counter > 1) {
        return;
      }

      let news = item.description;
      if (isJsonString(news)) {
        news = JSON.parse(news);
        news = news.text.text;
      }

      // Parse the given date, as it's in an ISO format which isn't as nicely readable for humans.
      let creationDate = new Date(item.created_at);

      // Add the news data into the relvant html objects.
      document.querySelector('#joinTheConversationNewsTitle').innerText = item.title;
      document.querySelector('#joinTheConversationNewsDate').innerText = getOrdinal(creationDate.getDate()) + ' ' + creationDate.toLocaleString('default', { month: 'long' }) + ' ' + creationDate.getFullYear();
      document.querySelector('#joinTheConversationNewsDescription').innerHTML = news;
    });
  }).catch(() => {

    // Delete the news conversation item as we're unable to load any news.
    if (joinTheConversationComponent !== null) {
      joinTheConversationComponent.remove();
    }
  });

  // Iterate through all the "join the conversation" containers, and give them a background color as defined in the variable file.
  let joinTheconversationContainerCounter = 0;
  document.querySelectorAll('.conversation-item').forEach(item => {
    item.style.backgroundColor = siteConfig.joinTheConversationBoxColours[joinTheconversationContainerCounter++];
  });

  // Add the twitter details into the relevant boxes.
  fetchApiData(siteConfig.api.domain + 'companies/' + siteConfig.api.company + '?referrer=' + siteConfig.api.referrer).then(company => {

    // Make sure this company has twitter, otherwise delete the "join the conversation" object.
    let creationDate = new Date();
    if (company['twitter'] !== '') {
      document.querySelector('#joinTheConversationTwitterDate').innerText = getOrdinal(creationDate.getDate()) + ' ' + creationDate.toLocaleString('default', { month: 'long' }) + ' ' + creationDate.getFullYear();
      document.querySelector('#joinTheConversationTwitterButton').setAttribute('href', company['twitter']);

      let n = company['twitter'].lastIndexOf('/');
      document.querySelector('#joinTheConversationTwitterTitle').innerText = '@' + company['twitter'].substring(n + 1);
    } else {
      document.querySelector('#join-the-conversation-twitter').remove();
    }

    // Make sure this company has twitter, otherwise delete the "join the conversation" object.
    if (company['linkedin'] !== '') {
      document.querySelector('#joinTheConversationLinkedInDate').innerText = getOrdinal(creationDate.getDate()) + ' ' + creationDate.toLocaleString('default', { month: 'long' }) + ' ' + creationDate.getFullYear();
      document.querySelector('#joinTheConversationLinkedInButton').setAttribute('href', company['linkedin']);

      let n = company['linkedin'].lastIndexOf('/');
      document.querySelector('#joinTheConversationLinkedInTitle').innerText = '#' + company['linkedin'].substring(n + 1);
    } else {
      document.querySelector('#join-the-conversation-linkedin').remove();
    }
  }).catch(() => {

    // Delete the news conversation item as we're unable to load any news.
    if (joinTheConversationComponent !== null) {
      joinTheConversationComponent.remove();
    }
  });
});